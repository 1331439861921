
.bg-img-extn{
    background-image: url("../../assets/Group\ 1000001095.png");
    background-position: center ;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 20px;
}

.box{
    box-shadow: inset rgb(72, 71, 71) 0px 11.49px 34.46px -22.97px, inset rgb(114, 109, 109) 0px 0px 26.7px 0px , inset rgba(0, 0, 0, 0.21) 0px 51.69px 45.94px -22.97px , inset rgba(0, 0, 0, 0.12) 0px 5.74px 22.97px -11.49px , inset rgba(0, 0, 0, 0.08) 0px 5.74px 5.74px -11.49px ;

}

.main-text {
    background: -webkit-linear-gradient(#FFFFFF, #8D8C8C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

