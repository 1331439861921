.aboutus-container {
  background-image: linear-gradient(150deg, rgb(58, 19, 2) 2%, black 50%);
}
.team-container {
  background-image: linear-gradient(-30deg, rgb(58, 19, 2) 2%, black 50%);
}
.tokenomics-container {
  background-image: linear-gradient(220deg, rgb(58, 19, 2) 2%, black 50%);
}

/* Team component styling */
img.team-img {
  object-fit: cover;
}

/* Aboutus styling */
.about-img {
  transition: ease 500ms;
  animation: rotateImg 1s linear infinite alternate;
}

.about-img:hover {
  transform: rotateY(180deg);
}
@keyframes rotateImg {
  0% {
    transform: scale(0.97);
  }
  100% {
    transform: scale(1);
  }
}

/* Roadmap component styling */
.roadmap-wrapper {
  max-width: 1920px;
  margin: 0 auto;
  margin-top: -40px;
  min-height: 100vh;
  overflow: hidden;
  background-color: #000;
}
.roadmap-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  height: 100px;
  margin: 140px 0 0 100px;
}
.roadmap-text-container h1 {
  font-size: 64px;
  font-weight: bold;
  color: #fff;
}
.roadmap-wrapper section {
  width: 100%;
  height: calc(100vh - 240px);
  position: relative;
}
.roadmap-wrapper section .dash-line {
  position: absolute;
  left: 50%;
  /* margin-top: 20vw; */
  margin-top: 200vh;
  transform: translateX(-50%) rotate(0deg);
  height: 100vw;
  width: 100vw;
  border-radius: 50%;
  background-image: url("/public/assets/eclipse.png");
  background-position: center;
  background-size: contain;
  animation: dashLineMoveUp 1800ms linear 2s 1 forwards;
}

.roadmap-wrapper section .dash-line .dot {
  position: absolute;
  height: 50px;
  width: 50px;
  background-color: #75380f;
  border-radius: 50%;
}
.roadmap-wrapper section .dash-line .dot:before {
  content: "";
  position: absolute;
  height: 28px;
  width: 28px;
  top: 11px;
  left: 11px;
  border-radius: 50%;
  background: linear-gradient(to right, #f59a30, #ff3503);
}

.dot:nth-child(1) {
  top: 8%;
  left: 21%;
}
.dot:nth-child(2) {
  top: 2%;
  left: 33%;
}
.dot:nth-child(3) {
  top: -1%;
  left: 50%;
  transform: translateX(-50%);
}
.dot:nth-child(4) {
  top: 1%;
  left: 64%;
}
.dot:nth-child(5) {
  top: 8%;
  left: 79%;
}
.roadmap-wrapper section .info-container {
  position: absolute;
  left: 50%;
  /* margin-top: 20vw; */
  margin-top: 200vh;
  transform: translateX(-50%) rotate(0deg);
  height: 100vw;
  width: 100vw;
  border-radius: 50%;
  animation: infoMoveUp 1800ms linear 2s 1 forwards;
}
.info:nth-child(1) {
  top: -4%;
  left: 10%;
}
.info:nth-child(2) {
  top: -11%;
  left: 22%;
}
.info:nth-child(3) {
  top: -13%;
  left: 38%;
}
.info:nth-child(4) {
  top: -11%;
  right: 35%;
}
.info:nth-child(5) {
  top: -4%;
  right: 20%;
}
.info::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 10%;
  width: 60%;
  border: 0.5px solid #fff;
}
.info::after {
  content: "";
  position: absolute;
  left: 80%;
  bottom: -81%;
  height: 80%;
  rotate: -30deg;
  border: 1px solid #fff;
}
.roadmap-wrapper section .earth-roadmap {
  position: absolute;
  left: 50%;
  /* margin-top:26vw; */
  margin-top: 200vh;
  transform: translateX(-50%);
  height: 86vw;
  width: 86vw;
  background: url("/public/assets/earthr.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  animation: earthMoveUp 800ms linear 1 forwards,
    earthRotate 100s linear 2s infinite;
  filter: drop-shadow(0 0 80px rgba(255, 35, 3, 0.75));
}

.roadmap-wrapper section .earth-line {
  position: absolute;
  left: 50%;
  /* margin-top: 23vw; */
  margin-top: 200vh;
  transform: translateX(-50%);
  height: 94vw;
  width: 94vw;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: earthLineMoveUp 800ms linear 1 1.2s forwards;
}
@media only screen and (max-width: 1580px) {
  .info::after {
    display: none;
  }
  .info::before {
    width: 80%;
  }
  .info:nth-child(1) {
    top: -4%;
    left: 15%;
  }
  .info:nth-child(2) {
    top: -12%;
    left: 28%;
  }
  .info:nth-child(3) {
    top: -15%;
    left: 44%;
  }
  .info:nth-child(4) {
    top: -12%;
    right: 28%;
  }
  .info:nth-child(5) {
    top: -4%;
    right: 15%;
  }
}
@media only screen and (max-width: 1280px) {
  .roadmap-text-container {
    margin: 80px 0 0 50px;
  }
  .roadmap-wrapper section {
    height: calc(100vh - 180px);
  }
}

@media only screen and (max-width: 1024px) {
  .roadmap-text-container {
    width: max-content;
    margin: 50px 0 0 100px;
  }
  .roadmap-text-container h1 {
    font-size: 36px;
    margin-right: 50px;
  }
  .roadmap-wrapper section .dash-line .dot {
    height: 36px;
    width: 36px;
  }
  .roadmap-wrapper section .dash-line .dot:before {
    height: 20px;
    width: 20px;
    top: 8px;
    left: 8px;
  }

  .dot:nth-child(1) {
    top: 7%;
    left: 20%;
  }
  .dot:nth-child(2) {
    top: 1%;
    left: 33%;
  }
  .dot:nth-child(3) {
    top: -2%;
    left: 50%;
    transform: translateX(-50%);
  }
  .dot:nth-child(4) {
    top: 1%;
    right: 33%;
  }
  .dot:nth-child(5) {
    top: 8%;
    right: 18%;
  }

  .roadmap-wrapper section .earth-roadmap {
    left: 0;
    bottom: 0;
    margin: 0;
    height: 96vw;
    width: 96vw;
    transform: translate(-40%, 50%) rotate(40deg);
    animation: earthMoveUp 800ms linear 1 forwards,
      earthRotateMobile 50s linear 2s infinite;
    filter: drop-shadow(0 0 100px rgba(255, 35, 3, 1));
  }
  .roadmap-wrapper section .earth-roadmap:active {
    animation-play-state: paused;
  }
  .roadmap-wrapper section .dash-line {
    left: 0;
    bottom: 0;
    margin: 0;
    height: 116vw;
    width: 116vw;
    transform: translate(-40%, 50%) rotate(40deg);
    animation: dashLineRotate 10s linear infinite;
  }
  .roadmap-wrapper section .earth-line {
    left: 0;
    bottom: 0;
    margin: 0;
    height: 104vw;
    width: 104vw;
    transform: translate(-40%, 50%);
  }
}

@media only screen and (max-width: 780px) {
  .roadmap-text-container {
    margin: 60px 0 0px 30px;
  }
  .roadmap-text-container h1 {
    margin-right: 25px;
  }
}
@media only screen and (max-width: 620px) {
  .roadmap-text-container {
    margin: 40px 0 0px 30px;
  }
  .roadmap-text-container h1 {
    margin-right: 25px;
  }
  .dot:nth-child(1) {
    top: 10%;
    left: 15%;
  }
  .dot:nth-child(2) {
    top: -1%;
    left: 32%;
  }
  .dot:nth-child(3) {
    top: -2%;
    left: 55%;
    transform: translateX(-50%);
  }
  .dot:nth-child(4) {
    top: 2%;
    left: 74%;
  }
  .dot:nth-child(5) {
    top: 12%;
    left: 86%;
  }
}

/* Roadmap component animations */
@keyframes earthMoveUp {
  100% {
    margin-top: 26vw;
  }
}
@keyframes dashLineMoveUp {
  100% {
    margin-top: 20vw;
    opacity: 1;
    scale: 1;
  }
}
@keyframes earthLineMoveUp {
  100% {
    margin-top: 23vw;
  }
}

@keyframes earthRotate {
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes earthRotateMobile {
  100% {
    transform: translate(-40%, 50%) rotate(360deg);
  }
}
@keyframes infoMoveUp {
  100% {
    margin-top: 20vw;
    transform: translateX(-50%) rotate(0deg);
  }
}

/* Roadmap component animations */
@keyframes earthMoveUp {
  100% {
    margin-top: 26vw;
  }
}
@keyframes dashLineMoveUp {
  100% {
    margin-top: 20vw;
    opacity: 1;
    scale: 1;
  }
}
@keyframes earthLineMoveUp {
  100% {
    margin-top: 23vw;
  }
}

@keyframes earthRotate {
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes infoMoveUp {
  100% {
    margin-top: 20vw;
    transform: translateX(-50%) rotate(0deg);
  }
}
@keyframes earthLineMoveUp {
  100% {
    margin-top: 23vw;
  }
}

@keyframes earthRotate {
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes infoMoveUp {
  100% {
    margin-top: 20vw;
    transform: translateX(-50%) rotate(0deg);
  }
}

/* Tokenomics styling */
.t-line {
  background: url("/public/assets/tokenomicsline.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}


.t-items {
  background-image: url("/public/assets/tokenomics.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  
}
.t-container {
  animation: tokenomics 50s linear infinite;

}
@keyframes tokenomics {
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}
@media only screen and (max-width: 1024px) {
  .t-container {
    animation: tokenomicsmobile 30s linear infinite;
  }
}
@keyframes tokenomicsmobile {
  100% {
    transform: translate(-50%, 50%) rotate(360deg);
  }
}
