.mainnet-container {
  background-image: url('/public/assets/mainnet-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Coming soon styling */
.earth{
  height:100vw;
  width:100vw;
  position:absolute;
  top:50%;
  left:50%;
  transform:translateX(-50%);

  border-radius:50%;
  background-image: url('/public/assets/earthr.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter:drop-shadow(0 0 100px rgba(255,35,3,0.4))
}