
.btn{
    background: linear-gradient(black, black) padding-box,
    linear-gradient(to bottom, #FF5500, #FF5500 ) border-box;
    border-radius: 48px;
    border-top: 3.25px solid transparent;
    border-bottom: 0.5px solid transparent;
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
}

