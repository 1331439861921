@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
.root,
#root,
#docs-root {
  --background: #000;
  --foreground: #fff;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;

  --gradient: linear-gradient(to right, var(--primary), var(--secondary));
  --input: 214.3 31.8% 91.4%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 47.4% 11.2%;

  --primary: #ff3503;
  --primary-foreground: 210 40% 98%;

  --secondary: #ffa100;
  --secondary-foreground: 222.2 47.4% 11.2%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 100% 50%;
  --destructive-foreground: 210 40% 98%;

  --ring: var(--border);

  --radius: 0.5rem;
}

.dark {
  --background: 224 71% 4%;
  --foreground: 213 31% 91%;

  --muted: 223 47% 11%;
  --muted-foreground: 215.4 16.3% 56.9%;

  --accent: 216 34% 17%;
  --accent-foreground: 210 40% 98%;

  --popover: 224 71% 4%;
  --popover-foreground: 215 20.2% 65.1%;

  --border: 216 34% 17%;
  --input: 216 34% 17%;

  --card: 224 71% 4%;
  --card-foreground: 213 31% 91%;

  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 1.2%;

  --secondary: 222.2 47.4% 11.2%;
  --secondary-foreground: 210 40% 98%;

  --destructive: 0 63% 31%;
  --destructive-foreground: 210 40% 98%;

  --ring: 216 34% 17%;

  --radius: 0.5rem;
}

body {
  background-color: var(--background);
  font-family: 'Kodchasan';
}




/* Cursor styling */
.cursor {
  width: 20px;
  height: 20px;
  border: 1px solid white;
  border-radius: 50%;
  position: absolute;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  animation: cursorAnim .5s infinite alternate;
  pointer-events: none;
  z-index: 10;
}

.cursor::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  border: 8px solid rgb(240, 85, 2);
  border-radius: 50%;
  opacity: .5;
  top: -1px;
  left: -1px;
  animation: cursorAnim2 .5s infinite alternate;
  
}

@keyframes cursorAnim {
  from {
      transform: scale(1);
  }
  to {
      transform: scale(.7);
  }
}

@keyframes cursorAnim2 {
  from {
      transform: scale(1);
  }
  to {
      transform: scale(.4);
  }
}

@keyframes cursorAnim3 {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(3);
  }
  100% {
      transform: scale(1);
      opacity: 0;
  }
}

.expand {
  animation: cursorAnim3 .5s forwards;
  border: 1px solid rgb(255,35,3);
}

