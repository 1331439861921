.wrapper {
  overflow: hidden;
  background-color: #000;
  min-height: 100vh;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
}
.wrapper .first-page {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: DisappearLeft 1200ms linear 8s 1 forwards,
    AppearLeft 1200ms ease-in 21.25s 1 forwards;
}
.wrapper .first-page .text-section {
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  text-wrap: wrap;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  line-height: 1.2;
}
.wrapper .first-page .text-section h1 {
  perspective: 800px;
  width: 280px;
  font-size: 72px;
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 6px;
}
.wrapper .first-page .text-section h1 > span {
  display: inline-block;
  transform: rotateY(90deg);
  opacity: 0;
  animation: rotate-text 400ms ease 1 forwards;
}
.wrapper .first-page .text-section h1 span:nth-child(2) {
  animation-delay: 100ms;
}
.wrapper .first-page .text-section h1 span:nth-child(3) {
  animation-delay: 200ms;
}
.wrapper .first-page .text-section h1 span:nth-child(4) {
  animation-delay: 300ms;
}
.wrapper .first-page .text-section h1 span:nth-child(5) {
  animation-delay: 400ms;
}
.wrapper .first-page .text-section h1 span:nth-child(6) {
  animation-delay: 500ms;
}
.wrapper .first-page .text-section h1 span:nth-child(7) {
  animation-delay: 600ms;
}
.wrapper .first-page .text-section h1 span:nth-child(8) {
  animation-delay: 700ms;
}
.wrapper .first-page .text-section h1 p {
  width: 600px;
  display: block;
  color: rgb(240, 85, 2);
  letter-spacing: 6px;
}
.wrapper .first-page .text-section h1 p span {
  display: inline-block;
  opacity: 0;
  transform: rotateY(90deg);
  animation: rotate-text 500ms ease 1 forwards;
}

.wrapper .first-page .text-section h1 p span:nth-child(1) {
  animation-delay: 800ms;
}
.wrapper .first-page .text-section h1 p span:nth-child(2) {
  animation-delay: 900ms;
}
.wrapper .first-page .text-section h1 p span:nth-child(3) {
  animation-delay: 1000ms;
}
.wrapper .first-page .text-section h1 p span:nth-child(4) {
  animation-delay: 1100ms;
}
.wrapper .first-page .text-section h1 p span:nth-child(5) {
  animation-delay: 1200ms;
}
.wrapper .first-page .text-section h1 p span:nth-child(6) {
  animation-delay: 1300ms;
}
.wrapper .first-page .text-section h1 p span:nth-child(7) {
  animation-delay: 1400ms;
}
.wrapper .first-page .text-section h1 p span:nth-child(8) {
  animation-delay: 1500ms;
}
.wrapper .first-page .text-section h1 p span:nth-child(9) {
  animation-delay: 1600ms;
}
.wrapper .first-page .text-section h2 {
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  width: 0%;
  opacity: 0;
  overflow: hidden;
  animation: typewritter-effect 2500ms steps(45, end) 1 forwards;
  animation-delay: 1800ms;
  color: #fff;
  margin-top: 20px auto;
}
.wrapper .first-page .text-section ul {
  padding: 30px 10px;
  color: #767676;
  list-style-type: circle;
  width: 100%;
}
.wrapper .first-page .text-section ul li {
  position: relative;
  left: 20px;
  padding: 12px 0;
  font-size: 22px;
  overflow: hidden;
  width: 0;
  white-space: nowrap;
  animation: typewritter-effect 800ms steps(45, end) 1 forwards;
}
.wrapper .first-page .text-section ul li:nth-child(1) {
  animation-delay: 3s;
}
.wrapper .first-page .text-section ul li:nth-child(2) {
  animation-delay: 4s;
}
.wrapper .first-page .text-section .btn-container {
  height: 60px;
  width: 220px;
  border-radius: 30px;
  background-color: transparent;
  display: grid;
  place-items: center;
  position: relative;
  z-index: 8;
  overflow: hidden;
}
.wrapper .first-page .text-section .btn-container::before {
  content: "";
  top: 0;
  right: 0;
  height: 0px;
  width: 0px;
  position: absolute;
  z-index: 0;
  background-color: #ff3503;
  animation: btn-glow-top 300ms linear 1 forwards;
  animation-delay: 5500ms;
}
.wrapper .first-page .text-section .btn-container::after {
  content: "";
  bottom: 0;
  left: 0;
  height: 0%;
  width: 0%;
  position: absolute;
  z-index: 0;
  background-color: #ff3503;
  animation: btn-glow-bottom 300ms linear 1 forwards;
  animation-delay: 5800ms;
}
.wrapper .first-page .text-section .btn-container button {
  height: 56px;
  width: 216px;
  border-radius: 30px;
  font-size: 20px;
  background-color: rgb(18, 6, 0);
  color: #000;
  cursor: pointer;
  outline: none;
  border: none;
  z-index: 10;
  text-transform: uppercase;
  opacity: 0;
  animation: opacity-full 250ms linear 1 forwards;
  animation-delay: 5000ms;
}
.wrapper .first-page .animation-section {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  height: 800px;
  width: 600px;
  right: 200px;
  perspective: 2500px;
}
.wrapper .first-page .animation-section .card {
  position: absolute;
  height: 70%;
  width: 70%;
  opacity: 0;
}
.wrapper .first-page .animation-section .card:nth-child(1) {
  top: 0;
  left: 0;
  transform: rotate(-20deg);
  animation: leftCard 6s linear 1 forwards;
  z-index: 100;
}
.wrapper .first-page .animation-section .card:nth-child(2) {
  top: 120px;
  right: -100px;
  z-index: 99;
  transform: rotate(20deg) rotateY(0deg);
  animation: rightCard 6s linear 1 forwards;
}
.wrapper .first-page .animation-section .card img.card-img {
  height: 100%;
  width: 100%;
  z-index: 100;
}
.wrapper .first-page .animation-section .card .card-blink {
  position: absolute;
  bottom: 64px;
  display: flex;
  align-items: center;
  left: 100px;
  color: #fff;
  font-size: 10px;
  animation: BlinkEffect 1000ms ease-in-out 6 forwards;
  animation-delay: 500ms;
}
.wrapper .first-page .animation-section .card .card-blink img {
  height: 40px;
  width: 100%;
}
.wrapper .first-page .animation-section .card .card-blink h1 {
  margin-left: -15px;
  font-weight: 900px;
  font-size: 24px;
  font-family: verdana;
  text-shadow: 0 0 5px rgba(255, 255, 255, 1);
}
.wrapper .first-page .animation-section .card .card-blink p {
  font-size: 20px;
  margin-left: 30px;
}
.wrapper .first-page .animation-section .card video {
  position: absolute;
  top: 44%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  height: 68%;
  width: 62%;
  opacity: 0;
  animation: video-overlay 2s linear 1 forwards;
  z-index: -50;
}
.wrapper .laptop-container {
  z-index: 5;
  position: absolute;
  right: -1200px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  height: 720px;
  width: 1200px;
  perspective: 5000px;
  animation: moveLaptopLeft 1500ms linear 8s 1 forwards,
    moveLaptopRight 1200ms linear 15s 1 forwards;
}

.wrapper .laptop-container .lt-top {
  display: grid;
  place-items: center;
  height: 98%;
  width: 80%;
  background: #000;
  border-radius: 36px 36px 16px 16px;
  border: 1px solid #fff;
  z-index: 20;
  padding-bottom: 20px;
  transform-origin: bottom;
  backface-visibility: hidden;
  transform: rotateX(-84deg);
  animation: openLaptop 1500ms linear 8s 1 forwards;
}
.wrapper .laptop-container .lt-top .lt-top-child {
  height: 90%;
  width: 92%;
  background-color: #fff;
  border-radius: 20px 20px 4px 4px;
  background-image: url("/public/assets/hero/bg-lt.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.wrapper .laptop-container .lt-bottom {
  position: relative;
  transform: translateY(-6px);
  height: 32px;
  width: 100%;
  border-bottom: 0.5px solid rgb(30, 30, 30);
  background-color: #bec3cb;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.25),
    0 8px 5px rgba(0, 0, 0, 0.5) inset, 0 -8px 5px rgba(0, 0, 0, 0.5) inset,
    10px 0 5px rgba(0, 0, 0, 0.5) inset;
  z-index: 50;
}
.wrapper .lt-text-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15%;
  color: #fff;
  animation: opacity-null 2000ms linear 14s 1 forwards;
  z-index: 10;
}
.wrapper .lt-text-container h1 {
  font-size: 56px;
  width: 600px;
  letter-spacing: 4px;
  line-height: 1.4;
  font-weight: 900;
  perspective: 5000px;
}
.wrapper .lt-text-container h1 span {
  display: inline-block;
  text-shadow: 0 0 80px rgba(255, 255, 255, 0.86);
  transform: rotateY(90deg);
  opacity: 0;
  animation: rotate-text 150ms linear 1 forwards;
}
.wrapper .lt-text-container h1 span:nth-child(1) {
  animation-delay: 9.1s;
}
.wrapper .lt-text-container h1 span:nth-child(2) {
  animation-delay: 9.2s;
}
.wrapper .lt-text-container h1 span:nth-child(3) {
  animation-delay: 9.3s;
}
.wrapper .lt-text-container h1 span:nth-child(4) {
  animation-delay: 9.4s;
}
.wrapper .lt-text-container h1 span:nth-child(5) {
  animation-delay: 9.5s;
}
.wrapper .lt-text-container h1 span:nth-child(6) {
  animation-delay: 9.6s;
}
.wrapper .lt-text-container h1 span:nth-child(7) {
  animation-delay: 9.7s;
}
.wrapper .lt-text-container h1 span:nth-child(8) {
  animation-delay: 9.8s;
}
.wrapper .lt-text-container h1 span:nth-child(9) {
  animation-delay: 9.9s;
}
.wrapper .lt-text-container h1 span:nth-child(10) {
  animation-delay: 10s;
}
.wrapper .lt-text-container h1 span:nth-child(11) {
  animation-delay: 10.1s;
}
.wrapper .lt-text-container h1 span:nth-child(12) {
  animation-delay: 10.2s;
}
.wrapper .lt-text-container h1 span:nth-child(13) {
  animation-delay: 10.3s;
}
.wrapper .lt-text-container h1 span:nth-child(14) {
  animation-delay: 10.4s;
}
.wrapper .lt-text-container h1 span:nth-child(15) {
  animation-delay: 10.5s;
}
.wrapper .lt-text-container h1 span:nth-child(16) {
  animation-delay: 10.6s;
}
.wrapper .lt-text-container h1 span:nth-child(17) {
  animation-delay: 10.7s;
}
.wrapper .lt-text-container h1 span:nth-child(18) {
  animation-delay: 10.8s;
}
.wrapper .lt-text-container h1 span:nth-child(19) {
  animation-delay: 10.9s;
}
.wrapper .lt-text-container h1 span:nth-child(20) {
  animation-delay: 11s;
}
.wrapper .lt-text-container h1 span:nth-child(21) {
  animation-delay: 11.1s;
}
.wrapper .lt-text-container h1 span:nth-child(22) {
  animation-delay: 11.2s;
}
.wrapper .lt-text-container h1 span:nth-child(23) {
  animation-delay: 11.3s;
}
.wrapper .lt-text-container h1 span:nth-child(24) {
  animation-delay: 11.4s;
}
.wrapper .lt-text-container h1 span:nth-child(25) {
  animation-delay: 11.5s;
}
.wrapper .lt-text-container h1 span:nth-child(26) {
  animation-delay: 11.6s;
}
.wrapper .lt-text-container p {
  margin-top: 50px;
  font-size: 28px;
  width: 500px;
  line-height: 1.7;
  color: #575757;
  opacity: 0;
  animation: opacity-fill 1200ms linear 12s 1 forwards;
}

.wrapper .animated-circles {
  position: absolute;
  transform: translate(-50%, -50%) rotate(-135deg);
  height: 500px;
  width: 500px;
  border-radius: 50%;
  animation: rotateArc 800ms linear 10s 1 forwards,
    rotateBackArc 400ms linear 15s 1 forwards;
}
.wrapper .animated-circles .circle {
  position: absolute;
  top: 0;
  right: 0;
  border: 80px solid #ff3503;
  border-left: transparent;
  border-radius: 0 250px 250px 0;
  filter: drop-shadow(0 0 15px #ff3503);
  height: 500px;
  width: 50%;
}
.wrapper .mobile-app {
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  color: #fff;
  perspective: 1000px;
  animation: DisappearRight 1200ms ease-in 21s 1 forwards;
}
.wrapper .mobile-app img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotateX(80deg);
  opacity: 0;
  height: 400px;
  width: 200px;
  position: absolute;
  z-index: 1000;
}
.wrapper .mobile-app h1 {
  font-size: 14em;
  font-weight: 900;
  text-transform: uppercase;
  margin: 10px 0;
}
.wrapper .mobile-app h1 span {
  display: inline-block;
  letter-spacing: 0px;
  animation: rotate-text 1200ms ease 1 forwards;
  opacity: 0;
  transform: rotateY(90deg);
}

.wrapper .mobile-app h1 span:nth-child(1) {
  animation-delay: 17.2s;
}
.wrapper .mobile-app h1 span:nth-child(2) {
  animation-delay: 17.4s;
}
.wrapper .mobile-app h1 span:nth-child(3) {
  animation-delay: 17.6s;
}
.wrapper .mobile-app h1 span:nth-child(4) {
  animation-delay: 17.8s;
}
.wrapper .mobile-app h1 span:nth-child(5) {
  animation-delay: 18s;
}
.wrapper .mobile-app h1 span:nth-child(6) {
  animation-delay: 18.2s;
}
.wrapper .mobile-app h1 span:nth-child(7) {
  animation-delay: 18.4s;
}
.wrapper .mobile-app h1 span:nth-child(8) {
  animation-delay: 18.6s;
}
.wrapper .mobile-app h2 {
  font-size: 4em;
  font-weight: 600;
  opacity: 0;
  animation: opacity-full 550ms linear 19.4s 1 forwards;
}
.wrapper .mobile-app p {
  margin-top: 20px;
  font-size: 3.6em;
  opacity: 0;
  width: 700px;
  line-height: 1.6;
  color: #676767;
  animation: opacity-full 550ms linear 20s 1 forwards;
}
.wrapper .mobile-app .btn-container {
  cursor: pointer;
  margin-top: 40px;
  height: 60px;
  width: 220px;
  border-radius: 30px;
  background-color: transparent;
  display: grid;
  place-items: center;
  position: relative;
  z-index: 8;
  overflow: hidden;
}
.wrapper .mobile-app .btn-container::before {
  content: "";
  top: 0;
  right: 0;
  height: 0px;
  width: 0px;
  position: absolute;
  z-index: 0;
  background-color: #fff;
  animation: btn-glow-top 300ms linear 1 forwards;
  animation-delay: 20.5s;
}
.wrapper .mobile-app .btn-container::after {
  content: "";
  bottom: 0;
  left: 0;
  height: 0%;
  width: 0%;
  position: absolute;
  z-index: 0;
  background-color: #fff;
  animation: btn-glow-bottom 300ms linear 1 forwards;
  animation-delay: 20.8s;
}
.wrapper .mobile-app .btn-container button {
  height: 56px;
  width: 216px;
  border-radius: 30px;
  font-size: 20px;
  background-color: rgb(18, 6, 0);
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  z-index: 10;
  text-transform: uppercase;
  opacity: 0;
  animation: opacity-full 250ms linear 1 forwards;
  animation-delay: 20s;
}
.wrapper .mobile-cards .mobile {
  position: absolute;
  height: 100vh;
  width: 100%;
  visibility: hidden;
  overflow: hidden;
}
.wrapper .mobile-cards .mobile img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 900px;
}
.wrapper .mobile-cards .mobile:nth-child(1) {
  animation: ShowMobileScreen 65ms linear 16.56s 1;
}
.wrapper .mobile-cards .mobile:nth-child(2) {
  animation: ShowMobileScreen 65ms linear 16.62s 1;
}
.wrapper .mobile-cards .mobile:nth-child(3) {
  animation: ShowMobileScreen 65ms linear 16.68s 1;
}
.wrapper .mobile-cards .mobile:nth-child(4) {
  animation: ShowMobileScreen 65ms linear 16.74s 1;
}
.wrapper .mobile-cards .mobile:nth-child(5) {
  animation: ShowMobileScreen 65ms linear 16.8s 1;
}
.wrapper .mobile-cards .mobile:nth-child(6) {
  animation: ShowMobileScreen 65ms linear 16.86s 1;
}
.wrapper .mobile-cards .mobile:nth-child(7) {
  animation: ShowMobileScreen 65ms linear 16.92s 1;
}
.wrapper .mobile-cards .mobile:nth-child(8) {
  animation: ShowMobileScreen 65ms linear 16.98s 1;
}
.wrapper .mobile-cards .mobile:nth-child(9) {
  animation: ShowMobileScreen 65ms linear 17.04s 1;
}

.wrapper .mobile-cards .mobile:nth-child(10) {
  animation: ShowMobileScreen 65ms linear 17.1s 1;
}
.wrapper .mobile-cards .mobile:nth-child(11) {
  animation: ShowMobileScreen 65ms linear 17.16s 1;
}
.wrapper .mobile-cards .mobile:nth-child(12) {
  animation: ShowMobileScreen 65ms linear 17.22s 1;
}
.wrapper .mobile-cards .mobile:nth-child(13) {
  animation: ShowMobileScreen 65ms linear 17.28s 1;
}
.wrapper .mobile-cards .mobile:nth-child(14) {
  animation: ShowMobileScreen 65ms linear 17.34s 1;
}
.wrapper .mobile-cards .mobile:nth-child(15) {
  animation: ShowMobileScreen 65ms linear 17.4s 1;
}
.wrapper .mobile-cards .mobile:nth-child(16) {
  animation: ShowMobileScreen 65ms linear 17.46s 1;
}
.wrapper .mobile-cards .mobile:nth-child(17) {
  animation: ShowMobileScreen 65ms linear 17.52s 1;
}
.wrapper .mobile-cards .mobile:nth-child(18) {
  animation: ShowMobileScreen 65ms linear 17.58s 1;
}
.wrapper .mobile-cards .mobile:nth-child(19) {
  animation: ShowMobileScreen 65ms linear 17.64s 1;
}

.wrapper .mobile-cards .mobile:nth-child(20) {
  animation: ShowMobileScreen 65ms linear 17.7s 1;
}

.wrapper .mobile-cards .mobile:nth-child(21) {
  animation: ShowMobileScreen 65ms linear 17.76s 1;
  left: 8px;
}
.wrapper .mobile-cards .mobile:nth-child(22) {
  animation: ShowMobileScreen 65ms linear 17.82s 1;
  left: 16px;
}
.wrapper .mobile-cards .mobile:nth-child(23) {
  animation: ShowMobileScreen 65ms linear 17.88s 1;
  left: 24px;
}
.wrapper .mobile-cards .mobile:nth-child(24) {
  animation: ShowMobileScreen 65ms linear 17.94s 1;
  left: 32px;
}
.wrapper .mobile-cards .mobile:nth-child(25) {
  animation: ShowMobileScreen 65ms linear 18s 1;
  left: 40px;
}
.wrapper .mobile-cards .mobile:nth-child(26) {
  animation: ShowMobileScreen 65ms linear 18.06s 1;
  left: 48px;
}
.wrapper .mobile-cards .mobile:nth-child(27) {
  animation: ShowMobileScreen 65ms linear 18.1s 1;
  left: 56px;
}
.wrapper .mobile-cards .mobile:nth-child(28) {
  animation: ShowMobileScreen 65ms linear 18.16s 1;
  left: 64px;
}
.wrapper .mobile-cards .mobile:nth-child(29) {
  animation: ShowMobileScreen 65ms linear 18.22s 1;
  left: 72px;
}

.wrapper .mobile-cards .mobile:nth-child(30) {
  animation: ShowMobileScreen 65ms linear 18.28s 1;
  left: 80px;
}
.wrapper .mobile-cards .mobile:nth-child(31) {
  animation: ShowMobileScreen 65ms linear 18.34s 1;
  left: 88px;
}
.wrapper .mobile-cards .mobile:nth-child(32) {
  animation: ShowMobileScreen 65ms linear 18.4s 1;
  left: 94px;
}
.wrapper .mobile-cards .mobile:nth-child(33) {
  animation: ShowMobileScreen 65ms linear 18.46s 1;
  left: 102px;
}
.wrapper .mobile-cards .mobile:nth-child(34) {
  animation: ShowMobileScreen 65ms linear 18.52s 1;
  left: 110px;
}
.wrapper .mobile-cards .mobile:nth-child(35) {
  animation: ShowMobileScreen 65ms linear 18.58s 1;
  left: 118px;
}
.wrapper .mobile-cards .mobile:nth-child(36) {
  animation: ShowMobileScreen 65ms linear 18.64s 1;
  left: 124px;
}
.wrapper .mobile-cards .mobile:nth-child(37) {
  animation: ShowMobileScreen 65ms linear 18.7s 1;
  left: 132px;
}
.wrapper .mobile-cards .mobile:nth-child(38) {
  animation: ShowMobileScreen 65ms linear 18.76s 1;
  left: 140px;
}
.wrapper .mobile-cards .mobile:nth-child(39) {
  animation: ShowMobileScreen 65ms linear 18.82s 1;
  left: 148px;
}

.wrapper .mobile-cards .mobile:nth-child(40) {
  animation: ShowMobileScreen 65ms linear 18.88s 1;
  left: 156px;
}
.wrapper .mobile-cards .mobile:nth-child(41) {
  animation: ShowMobileScreen 65ms linear 18.94s 1;
  left: 164px;
}
.wrapper .mobile-cards .mobile:nth-child(42) {
  animation: ShowMobileScreen 65ms linear 19s 1;
  left: 172px;
}
.wrapper .mobile-cards .mobile:nth-child(43) {
  animation: ShowMobileScreen 65ms linear 19.06s 1;
  left: 180px;
}
.wrapper .mobile-cards .mobile:nth-child(44) {
  animation: ShowMobileScreen 65ms linear 19.12s 1;
  left: 188px;
}

.wrapper .mobile-cards .mobile:nth-child(45) {
  animation: ShowMobileScreen 65ms linear 19.18s 1;
  left: 196px;
}
.wrapper .mobile-cards .mobile:nth-child(46) {
  animation: ShowMobileScreen 65ms linear 19.24s 1;
  left: 202px;
}
.wrapper .mobile-cards .mobile:nth-child(47) {
  animation: ShowMobileScreen 65ms linear 19.3s 1;
  left: 210px;
}
.wrapper .mobile-cards .mobile:nth-child(48) {
  animation: ShowMobileScreen 65ms linear 19.36s 1;
  left: 218px;
}
.wrapper .mobile-cards .mobile:nth-child(49) {
  animation: ShowMobileScreen 65ms linear 19.42s 1;
  left: 226px;
}

.wrapper .mobile-cards .mobile:nth-child(50) {
  animation: ShowMobileScreen 65ms linear 19.48s 1 forwards,
    DisappearRight 600ms linear 21.25s 1 forwards;
  left: 234px;
}

/* Hero section first-page container animation */
@keyframes DisappearLeft {
  100% {
    transform: translate(-200%, -50%);
  }
}
@keyframes DisappearRight {
  100% {
    transform: translate(200%, -50%);
    opacity: 0;
  }
}
@keyframes AppearLeft {
  100% {
    transform: translate(-50%, -50%);
  }
}
/* Hero section first-page text-animations */
@keyframes rotate-text {
  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}
@keyframes typewritter-effect {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}
@keyframes opacity-full {
  100% {
    opacity: 1;
    color: #fff;
  }
}
@keyframes btn-glow-top {
  0% {
    width: 0;
    top: 0;
    height: 50%;
  }
  100% {
    top: 0;
    width: 100%;
    height: 50%;
  }
}
@keyframes btn-glow-bottom {
  0% {
    height: 50%;
  }
  100% {
    width: 100%;
    height: 50%;
  }
}

/* Hero section first-page card-animation*/
@keyframes leftCard {
  25% {
    top: 100px;
    opacity: 1;
  }
  50% {
    transform: rotate(-20deg) rotateY(-25deg);
    top: 160px;
  }
  75% {
    top: 100px;
  }
  100% {
    top: -10px;
    transform: rotate(-22deg) rotateY(20deg);
    opacity: 1;
  }
}
@keyframes rightCard {
  25% {
    top: -30px;
    transform: rotateY(30deg) rotate(20deg);
    opacity: 1;
  }
  50% {
    top: -50px;
  }
  75% {
    top: 60px;
  }
  100% {
    top: 100px;
    opacity: 1;
  }
}
@keyframes BlinkEffect {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes video-overlay {
  100% {
    opacity: 1;
  }
}
/* Hero section second-page animations */
@keyframes openLaptop {
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes moveLaptopLeft {
  100% {
    right: -240px;
  }
}
@keyframes moveLaptopRight {
  50% {
    opacity: 0;
  }
  100% {
    right: -1200px;
    opacity: 0;
  }
}

@keyframes opacity-fill {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    color: #fff;
  }
}
@keyframes opacity-null {
  100% {
    opacity: 0;
  }
}
@keyframes rotate-text {
  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}
@keyframes rotate1 {
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotate2 {
  100% {
    transform: translateX(-50%) rotate(180deg);
  }
}
@keyframes rotate3 {
  100% {
    transform: translateY(-50%) rotate(90deg);
  }
}

/* Hero section third-page animation */
@keyframes rotateArc {
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}
@keyframes rotateBackArc {
  100% {
    transform: translate(-50%, -50%) rotate(-135deg);
  }
}

@keyframes ShowMobileScreen {
  100% {
    visibility: visible;
  }
}
@keyframes PopupMobile {
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotateX(0deg);
  }
}
@keyframes MoveBack {
  100% {
    top: 20%;
    z-index: 0;
    opacity: 0.35;
  }
}

@keyframes DisappearMobile {
  100% {
    display: none;
  }
}

/* Animation for laptop screens */
@keyframes moveLaptopLeftL {
  100% {
    right: -480px;
  }
}
@keyframes moveLaptopLeftL2 {
  100% {
    right: 50%;
    transform: translateX(50%);
  }
}

/* Hero Gradient */
.hero-gradient {
  background-image: linear-gradient(329.5deg, rgb(143, 44, 1), black 40%);
}

@media (max-width: 1700px) {
  /* first page media */
  .wrapper .first-page .text-section {
    left: 80px;
  }
  .wrapper .first-page .text-section h1 {
    font-size: 64px;
    width: 250px;
  }
  .wrapper .first-page .text-section h2 {
    font-size: 24px;
  }
  .wrapper .animation-section {
    height: 600px;
    width: 460px;
    right: 100px;
  }
  .wrapper .animation-section .card .card-blink {
    left: 60px;
    bottom: 40px;
  }
  .wrapper .animation-section .card .card-blink h1 {
    margin-top: -8px;
  }
  .wrapper .animation-section .card .card-blink p {
    margin-left: 10px;
    margin-top: -8px;
  }
  /* second page media */
  .wrapper .laptop-container {
    height: 600px;
    width: 1000px;
  }
  .wrapper .lt-text-container {
    left: 10%;
  }
  .wrapper .lt-text-container h1 {
    font-size: 50px;
    width: 560px;
  }
  /* third page media */
  .wrapper .mobile-app h2 {
    font-size: 3.6em;
  }
  .wrapper .mobile-app p {
    font-size: 2.4em;
  }
  .wrapper .mobile-cards .mobile img {
    height: 800px;
  }
}

@media only screen and (max-width: 1680px) {
  .wrapper .first-page .animation-section {
    scale: 0.9;
    right: 80px;
  }
}
/* third page media */

@media only screen and (max-width: 1536px) {
  .wrapper .mobile-cards .mobile {
    overflow: hidden;
    position: absolute;
    height: 100vh;
    width: 100%;
    visibility: hidden;
  }
  .wrapper .mobile-cards .mobile img {
    position: absolute;
    top: 50%;
    height: 600px;
  }

  .wrapper .mobile-cards .mobile:nth-child(24) {
    left: 120px;
  }
  .wrapper .mobile-cards .mobile:nth-child(25) {
    left: 140px;
  }
  .wrapper .mobile-cards .mobile:nth-child(26) {
    left: 160px;
  }
  .wrapper .mobile-cards .mobile:nth-child(27) {
    left: 170px;
  }
  .wrapper .mobile-cards .mobile:nth-child(28) {
    left: 180px;
  }
  .wrapper .mobile-cards .mobile:nth-child(29) {
    left: 185px;
  }

  .wrapper .mobile-cards .mobile:nth-child(30) {
    left: 190px;
  }
  .wrapper .mobile-cards .mobile:nth-child(31) {
    left: 200px;
  }
  .wrapper .mobile-cards .mobile:nth-child(32) {
    left: 210px;
  }
  .wrapper .mobile-cards .mobile:nth-child(33) {
    left: 220px;
  }
  .wrapper .mobile-cards .mobile:nth-child(34) {
    left: 230px;
  }
  .wrapper .mobile-cards .mobile:nth-child(35) {
    left: 240px;
  }
  .wrapper .mobile-cards .mobile:nth-child(36) {
    left: 250px;
  }
  .wrapper .mobile-cards .mobile:nth-child(37) {
    left: 260px;
  }
  .wrapper .mobile-cards .mobile:nth-child(38) {
    left: 270px;
  }
  .wrapper .mobile-cards .mobile:nth-child(39) {
    left: 280px;
  }

  .wrapper .mobile-cards .mobile:nth-child(40) {
    left: 290px;
  }
  .wrapper .mobile-cards .mobile:nth-child(41) {
    left: 300px;
  }
  .wrapper .mobile-cards .mobile:nth-child(42) {
    left: 310px;
  }
  .wrapper .mobile-cards .mobile:nth-child(43) {
    left: 320px;
  }
  .wrapper .mobile-cards .mobile:nth-child(44) {
    left: 330px;
  }

  .wrapper .mobile-cards .mobile:nth-child(45) {
    left: 340px;
  }
  .wrapper .mobile-cards .mobile:nth-child(46) {
    left: 350px;
  }
  .wrapper .mobile-cards .mobile:nth-child(47) {
    left: 360px;
  }
  .wrapper .mobile-cards .mobile:nth-child(48) {
    left: 370px;
  }
  .wrapper .mobile-cards .mobile:nth-child(49) {
    left: 380px;
  }

  .wrapper .mobile-cards .mobile:nth-child(50) {
    left: 400px;
  }
}

/* second page media */
@media only screen and (max-width: 1500px) {
  .wrapper .lt-text-container {
    left: 5%;
  }
  .wrapper .lt-text-container h1 {
    font-size: 44px;
    width: 500px;
  }
}

/* third page media */
@media only screen and (max-width: 1350px) {
  .wrapper .mobile-app img {
    animation: PopupMobile 600ms linear 18s 1 forwards,
      MoveBack 500ms ease-in-out 19s 1 forwards;
  }
  .wrapper .mobile-cards .mobile {
    display: none;
  }
}

@media only screen and (max-width: 1280px) {
  /* first page media */
  .wrapper .first-page .text-section {
    top: 55%;
    width: 100%;
    padding: 120px 40px;
    left: 100px;
    min-height: 100vh;
  }
  .wrapper .first-page .text-section h1 {
    font-size: 36px;
    width: 160px;
  }
  .wrapper .first-page .text-section h2 {
    font-size: 24px;
    height: max-content;
    display: inline;
    width: 100%;
    opacity: 0;
    margin-top: 30px;
    text-wrap: wrap;
    animation: opacity-full 1s linear 2s 1 forwards;
  }
  .wrapper .first-page .text-section ul {
    margin-top: 30px;
    list-style: none;
    height: max-content;
    padding: 0;
  }
  .wrapper .first-page .text-section ul li {
    font-size: 16px;
    text-wrap: wrap;
    display: inline-block;
    width: 100%;
    opacity: 0;
    animation: opacity-full 1s linear 1 forwards;
    padding-top: 0;
    margin-left: -20px;
  }
  .wrapper .first-page .text-section ul li:nth-child(1) {
    animation-delay: 2.5s;
  }
  .wrapper .first-page .text-section ul li:nth-child(2) {
    animation-delay: 3s;
  }
  .wrapper .first-page .text-section .btn-container {
    margin-top: 24px;
  }
  .wrapper .first-page .text-section .btn-container::before {
    animation-delay: 4500ms;
  }
  .wrapper .first-page .text-section .btn-container::after {
    animation-delay: 4800ms;
  }
  .wrapper .first-page .text-section .btn-container button {
    animation-delay: 4000ms;
  }
  .wrapper .animation-section .card {
    display: none;
  }
  /* second page media */
  .wrapper .laptop-container {
    height: 500px;
    width: 900px;
  }
  .wrapper .lt-text-container h1 {
    font-size: 40px;
    width: 450px;
  }
  .wrapper .lt-text-container p {
    margin-top: 30px;
  }
}

/* third page media */
@media only screen and (max-width: 1080px) {
  .wrapper .mobile-app {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .wrapper .mobile-app h1 {
    font-size: 12em;
  }
  .wrapper .mobile-app h2 {
    font-size: 3em;
  }
  .wrapper .mobile-app p {
    font-size: 2.4em;
  }
  .wrapper .mobile-cards {
    display: none;
  }
}

/* second page media */
@media only screen and (max-width: 1024px) {
  .wrapper .laptop-container {
    animation: moveLaptopLeftL 1500ms linear 8s 1 forwards,
      moveLaptopRight 1200ms linear 15s 1 forwards;
  }
}

@media only screen and (max-width: 880px) {
  /* second page media */
  .wrapper .laptop-container {
    animation: moveLaptopLeftL2 1500ms linear 8s 1 forwards,
      moveLaptopRight 1200ms linear 15s 1 forwards;
    width: 94vw;
    height: 56vw;
    top: 260px;
  }
  .wrapper .laptop-container .lt-top {
    border-radius: 16px;
    padding-bottom: 0;
  }
  .wrapper .laptop-container .lt-bottom {
    height: 20px;
  }
  .wrapper .lt-text-container {
    top: 120px;
    width: 85vw;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .wrapper .lt-text-container h1 {
    font-size: 28px;
    width: 340px;
    margin: 0 auto;
  }
  .wrapper .lt-text-container p {
    margin-top: calc(56vw + 80px);
    margin-bottom: 15vh;
    font-size: 24px;
    width: 90vw;
  }
  /* third page media */
  .wrapper .mobile-app {
    width: 100%;
    left: 50%;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    padding: 0 40px;
  }
  .wrapper .mobile-app h1 {
    font-size: 13vw;
  }
  .wrapper .mobile-app h2 {
    font-size: 6vw;
  }
  .wrapper .mobile-app p {
    width: 95vw;
    font-size: 24px;
    text-wrap: wrap;
  }
}

/* first page media */
@media only screen and (max-width: 680px) {
  .wrapper .first-page .text-section {
    left: 0;
  }
}
