.bg-img-request{
    background-image: url("../../assets/Group\ 1000002416.svg");
    background-position: center ;
    background-size: cover;
    background-repeat: no-repeat;
}

.main-text {
    background: -webkit-linear-gradient(#FFFFFF, #8D8C8C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }