.input{
    background: linear-gradient(black, black) padding-box,
    linear-gradient(to bottom, #FF5500, #FF5500 ) border-box;
    border-radius: 48px;
    border-top: 3.25px solid transparent;
    border-bottom: 0.5px solid transparent;
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
}

.box{
    box-shadow: inset rgb(72, 71, 71) 0px 11.49px 34.46px -22.97px, inset rgb(114, 109, 109) 0px 0px 20.7px 0px , inset rgba(0, 0, 0, 0.21) 0px 51.69px 40.94px -22.97px , inset rgba(0, 0, 0, 0.12) 0px 5.74px 20.97px -11.49px , inset rgba(0, 0, 0, 0.08) 0px 5.74px 5.74px -11.49px ;

}
