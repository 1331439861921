.home-hero-container {
  background: url("/public/assets/bg-bottom-right.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.summary-container {
  background: url("/public/assets/bg-top-right.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.unlock-container {
  background: url("/public/assets/bg-right-center.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.social-bg {
  background: url("/public/assets/social-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.featured-container {
  background: url("/public/assets/bg-featured.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

/* Featured News Styling */
.f-card:hover img.f-img {
  scale: 1.05;
}
/* Summary styling */
.s-slider {
  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide > div {
    padding: 0 20px;
  }
}

.animated-circles-c1,
.animated-circles-c2 {
  top: 10%;
  height: 500px;
  width: 500px;
  transform: translate(-55%, 0) rotate(-180deg);
  z-index: -99;
  transition: all 1200ms ease-in-out;
  .circle {
    position: absolute;
    top: 0;
    right: 0;
    border: 80px solid #ff3503;
    border-left: transparent;
    border-radius: 0 250px 250px 0;
    filter: drop-shadow(0 0 15px #ff3503);
    height: 500px;
    width: 50%;
  }
}
.animated-circles-c2 {
  right: 0;
  top: 30%;
  bottom: 10px;
  transform: translateX(55%) rotate(0deg);
  .circle {
    border-color: #ff9900;
  }
}

.summary {
  background-image: linear-gradient(210deg, rgb(143, 44, 1), black 40%);
  z-index: -99;
}

.summary:hover .animated-circles-c1 {
  transform: translate(-50%, 0) rotate(0deg);
}
.summary:hover .animated-circles-c2 {
  transform: translateX(50%) rotate(180deg);
}

@media only screen and (max-width: 1536px) {
  .animated-circles-c1,
  .animated-circles-c2 {
    height: 400px;
    width: 400px;
    .circle {
      height: 400px;
      border-radius: 0 200px 200px 0;
      border-width: 50px;
    }
  }
}
@media only screen and (max-width: 1280px) {
  .animated-circles-c1,
  .animated-circles-c2 {
    height: 300px;
    width: 300px;
    .circle {
      height: 300px;
      border-radius: 0 150px 150px 0;
      border-width: 60px;
    }
  }
}

/* Discover section */

.bg-element {
  backdrop-filter: blur(28px);
  background-color: rgba(0, 0, 0, 0.61);
}

.discover-bg1 {
  background-image: radial-gradient(
    circle at center,
    rgb(143, 44, 1) 0%,
    black 40%
  );
}

.discover-business-bg {
  background-image: linear-gradient(43deg, rgb(143, 44, 1), black 40%);
}
.discover-payment-bg {
  background-image: linear-gradient(138deg, rgb(143, 44, 1), black 40%);
}
/* .discover-bg2{
  background-image: linear-gradient(285deg,rgb(143, 44, 1),black 25%);
} */

.discover-container {
  display: grid;
  grid-template-columns: 1fr 1.2fr 1.2fr;
  gap: 1.6rem;
  transition: 500ms;
  align-items: center;
  grid-template-areas:
    "dis-left top-left top-right"
    "dis-left bottom bottom";
}

.dis-left {
  grid-area: dis-left;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
/* Hover animations for cards */
.dis-left,
.top-left,
.top-right,
.bottom,
.left-top,
.right-top,
.bill-right,
.business-top-left,
.business-top-right,
.business-left,
.business-bottom,
.business-2-left-top,
.business-2-right-top,
.business-2-bottom,
.business-2-bill-right,
.payment-top-left,
.payment-left,
.payment-top-right,
.payment-bottom {
  position: relative;
  overflow: hidden;
}

.dis-left::before,
.top-left::before,
.top-right::before,
.bottom::before,
.left-top::before,
.right-top::before,
.bill-right::before,
.business-top-left::before,
.business-top-right::before,
.business-left::before,
.business-bottom::before,
.business-2-left-top::before,
.business-2-right-top::before,
.business-2-bottom::before,
.business-2-bill-right::before,
.payment-top-left::before,
.payment-left::before,
.payment-top-right::before,
.payment-bottom::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #ff35038c, #ff350323, #000);
  transition: top 200ms;
  z-index: -1;
}

.dis-left:hover::before,
.top-left:hover::before,
.top-right:hover::before,
.bottom:hover::before,
.left-top:hover:before,
.right-top:hover::before,
.bill-right:hover::before,
.business-top-left:hover::before,
.business-top-right:hover::before,
.business-left:hover::before,
.business-bottom:hover::before,
.business-2-left-top:hover:before,
.business-2-right-top:hover::before,
.business-2-bottom:hover:before,
.business-2-bill-right:hover::before,
.payment-top-left:hover::before,
.payment-left:hover::before,
.payment-top-right:hover::before,
.payment-bottom:hover::before {
  top: 0;
}

.top-left {
  grid-area: top-left;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 317px;
}
.top-left > img {
  height: 75%;
}

.top-right {
  grid-area: top-right;
  display: flex;
  justify-content: center;
  height: 317px;
}
.top-right > img {
  height: 75%;
}

.bottom {
  grid-area: bottom;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 317px;
}

:where(.left, .top-left, .top-right, .bottom) {
  transition: 300ms;
}

.discover-container:has(.dis-left:hover) {
  grid-template-columns: 1.2fr 1fr 1fr;
}

.discover-container:has(.top-left:hover) {
  grid-template-columns: 1fr 1.4fr 1fr;
}

.discover-container:has(.top-right:hover) {
  grid-template-columns: 1fr 1fr 1.4fr;
}

/* Discover section mobile */

/* Discover section */
.d-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
}
.d-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

.discover-container-2 {
  display: grid;
  grid-template-columns: 1.2fr 1.2fr 1fr;
  gap: 1.6rem;
  transition: 500ms;
  align-items: center;
  grid-template-areas:
    "left-top right-top bill-right"
    "bottom bottom bill-right";
}

.discover-container-2 > .left-top {
  grid-area: left-top;
  display: flex;
  justify-content: center;
  height: 318px;
}

.discover-container-2 > .left-top > img {
  height: 75%;
}

.discover-container-2 > .right-top {
  grid-area: right-top;
  display: flex;
  justify-content: center;
  height: 318px;
}

.discover-container-2 > .right-top > img {
  height: 75%;
}

.discover-container-2 > .bill-right {
  grid-area: bill-right;
  display: flex;
  justify-content: center;
  height: 100%;
}

.discover-container-2 > .bill-right > img {
  height: 75%;
}

.discover-container-2 > .bottom {
  grid-area: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 317px;
}

:where(.left-top .right-top .bill-right .bottom) {
  transition: 300ms;
}

.discover-container-2:has(.left-top:hover) {
  grid-template-columns: 1.3fr 1fr 0.9fr;
}

.discover-container-2:has(.right-top:hover) {
  grid-template-columns: 1fr 1.3fr 0.9fr;
}

.discover-container-2:has(.bill-right:hover) {
  grid-template-columns: 1fr 1fr 1.3fr;
}

@media only screen and (max-width: 640px) {
  .discover-container {
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "dis-left top-left"
      "dis-left top-right"
      "bottom bottom";
    overflow: hidden;
  }
  .top-left {
    height: 195px;
  }
  .discover-container > .top-right {
    height: 195px;
  }
  .discover-container > .bottom {
    height: 195px;
  }

  .discover-container:has(.dis-left:hover) {
    grid-template-columns: 1fr 1fr;
  }

  .discover-container:has(.top-left:hover) {
    grid-template-columns: 1fr 1fr;
  }

  .discover-container:has(.top-right:hover) {
    grid-template-columns: 1fr 1fr;
  }
  .discover-container-2 {
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "left-top  bill-right"
      "right-top bill-right"
      "bottom bottom";
    overflow: hidden;
  }
  .discover-container-2 > .left-top {
    height: 195px;
  }
  .discover-container-2 > .right-top {
    height: 195px;
  }
  .discover-container-2 > .bottom {
    height: 195px;
  }
  .discover-container-2 > .bottom > img {
    width: 50%;
  }
  .discover-container-2:has(.left-top:hover) {
    grid-template-columns: 1fr 1fr;
  }

  .discover-container-2:has(.right-top:hover) {
    grid-template-columns: 1fr 1fr;
  }

  .discover-container-2:has(.bill-right:hover) {
    grid-template-columns: 1fr 1fr;
  }
}

/* Business section */
.business-container {
  display: grid;
  grid-template-columns: 1fr 1.2fr 1.2fr;
  gap: 1.6rem;
  transition: 500ms;
  align-items: center;
  grid-template-areas:
    "business-left business-top-left business-top-right"
    "business-left business-bottom business-bottom";
}

.business-left {
  grid-area: business-left;
  display: flex;
  justify-content: center;
  height: 100%;
}

.business-top-left {
  grid-area: business-top-left;
  display: flex;
  justify-content: center;
  height: 317px;
}
.business-top-left > img {
  height: 75%;
}

.business-top-right {
  grid-area: business-top-right;
  display: flex;
  justify-content: center;
  height: 317px;
}

.business-top-right > img {
  height: 75%;
}

.business-bottom {
  grid-area: business-bottom;
  display: flex;
  justify-content: center;
  height: 317px;
}

:where(
    .business-left,
    .business-top-left,
    .business-top-right,
    .business-bottom
  ) {
  transition: 300ms;
}

.business-container:has(.business-left:hover) {
  grid-template-columns: 1.2fr 1fr 1fr;
}

.business-container:has(.business-top-left:hover) {
  grid-template-columns: 1fr 1.4fr 1fr;
}

.business-container:has(.business-top-right:hover) {
  grid-template-columns: 1fr 1fr 1.4fr;
}

/* merchants 2nd */

.business-container-2 {
  display: grid;
  grid-template-columns: 1.2fr 1.2fr 1fr;
  gap: 1.6rem;
  transition: 500ms;
  align-items: center;
  grid-template-areas:
    "business-2-left-top business-2-right-top business-2-bill-right"
    "business-2-bottom business-2-bottom business-2-bill-right";
}

.business-container-2 > .business-2-left-top {
  grid-area: business-2-left-top;
  display: flex;
  justify-content: center;
  height: 318px;
}
.business-container-2 > .business-2-right-top {
  grid-area: business-2-right-top;
  display: flex;
  justify-content: center;
  height: 318px;
}

.business-container-2 > .business-2-right-top > img {
  height: 75%;
}

.business-container-2 > .business-2-bill-right {
  grid-area: business-2-bill-right;
  display: flex;
  justify-content: center;
  height: 100%;
}

.business-container-2 > .business-2-bottom {
  grid-area: business-2-bottom;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 317px;
}

:where(
    .business-2-left-top
      .business-2-right-top
      .business-2-bill-right
      .business-2-bottom
  ) {
  transition: 300ms;
}

.business-container-2:has(.business-2-left-top:hover) {
  grid-template-columns: 1.3fr 1fr 0.9fr;
}

.business-container-2:has(.business-2-right-top:hover) {
  grid-template-columns: 1fr 1.3fr 0.9fr;
}

.business-container-2:has(.business-2-bill-right:hover) {
  grid-template-columns: 1fr 1fr 1.3fr;
}
@media only screen and (max-width: 640px) {
  .business-container {
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "business-left business-top-left "
      "business-left business-top-right"
      "business-bottom business-bottom";
  }
  .business-top-left {
    height: 195px;
  }
  .business-top-right {
    height: 195px;
  }
  .business-bottom {
    height: 195px;
  }
  .business-bottom > div {
    width: 50%;
  }

  .business-bottom > img {
    width: 183px;
  }
  .business-container:has(.business-left:hover) {
    grid-template-columns: 1fr 1fr;
  }

  .business-container:has(.business-top-left:hover) {
    grid-template-columns: 1fr 1fr;
  }

  .business-container:has(.business-top-right:hover) {
    grid-template-columns: 1fr 1fr;
  }
  .business-container-2 {
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "business-2-left-top  business-2-bill-right"
      "business-2-right-top business-2-bill-right"
      "business-2-bottom business-2-bottom";
    overflow: hidden;
  }
  .business-container-2 > .business-2-left-top {
    height: 195px;
  }
  .business-container-2 > .business-2-right-top {
    height: 195px;
  }
  .business-container-2 > .business-2-bottom {
    height: 195px;
  }

  .business-container-2 > .business-2-bottom > img {
    height: 183px;
  }

  .business-container-2:has(.business-2-left-top:hover) {
    grid-template-columns: 1fr 1fr;
  }

  .business-container-2:has(.business-2-right-top:hover) {
    grid-template-columns: 1fr 1fr;
  }

  .business-container-2:has(.business-2-bill-right:hover) {
    grid-template-columns: 1fr 1fr;
  }
}

/* payment section */
.payment-container {
  display: grid;
  grid-template-columns: 1fr 1.2fr 1.2fr;
  gap: 1.6rem;
  transition: 500ms;
  align-items: center;
  grid-template-areas:
    "payment-left payment-top-left payment-top-right"
    "payment-left payment-bottom payment-bottom";
}

.payment-left {
  grid-area: payment-left;
  display: flex;
  justify-content: center;
  height: 100%;
}

.payment-left > img {
  height: 75%;
}

.payment-top-left {
  grid-area: payment-top-left;
  display: flex;
  justify-content: center;
  height: 317px;
}

.payment-top-left > img {
  height: 75%;
}

.payment-top-right {
  grid-area: payment-top-right;
  display: flex;
  justify-content: center;
  height: 317px;
}
.payment-top-right > img {
  height: 75%;
}

.payment-bottom {
  grid-area: payment-bottom;
  display: flex;
  justify-content: center;
  height: 317px;
}

:where(.payment-left, .payment-top-left, .payment-top-right, .payment-bottom) {
  transition: 300ms;
}

.payment-container:has(.payment-left:hover) {
  grid-template-columns: 1.2fr 1fr 1fr;
}

.payment-container:has(.payment-top-left:hover) {
  grid-template-columns: 1fr 1.4fr 1fr;
}

.payment-container:has(.payment-top-right:hover) {
  grid-template-columns: 1fr 1fr 1.4fr;
}

@media only screen and (max-width: 640px) {
  .payment-container {
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "payment-left payment-top-left "
      "payment-left payment-top-right"
      "payment-bottom payment-bottom";
  }
  .payment-top-left {
    height: 195px;
  }
  .payment-top-right {
    height: 195px;
  }
  .payment-bottom {
    height: 195px;
  }
  .payment-container:has(.payment-left:hover) {
    grid-template-columns: 1fr 1fr;
  }

  .payment-container:has(.payment-top-left:hover) {
    grid-template-columns: 1fr 1fr;
  }

  .payment-container:has(.payment-top-right:hover) {
    grid-template-columns: 1fr 1fr;
  }

  .payment-bottom > img {
    height: 183px;
  }
}

.slick-list {
  margin: 0 -5px;
}
.slick-slide > div {
  padding: 0 30px;
}

.left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
}
.right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -30px;
}
@media only screen and (max-width: 1023px) {
  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide > div {
    padding: 0 10px;
  }
}
/* Social component Styling */

.s-text {
  width: 0;
  white-space: nowrap;
  margin-left: 0;
  overflow: hidden;
  animation: increment 2s steps(16, end) 1 forwards;
}

/* Laptop with social icons styling */

.social-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
}
.laptop .lp.active {
  animation: open 800ms linear 1 forwards;
}
.laptop {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  perspective: 5000px;
  .lp {
    height: 530px;
    width: 900px;
    border-radius: 36px 36px 0 0;
    background: #000;
    padding-top: 20px;
    border: 1px solid rgb(67, 67, 67);
    transform-origin: bottom;
    backface-visibility: hidden;
    transform: rotateX(-90deg);

    .child {
      margin: 0 auto;
      height: 97%;
      width: 96%;
      background-color: #fff;
      border-radius: 16px 16px 0 0;
      position: relative;
      background-image: url("/public/assets/social.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  @media only screen and (max-width: 1260px) {
    .lp {
      height: 420px;
      width: 700px;
      border-radius: 26px 26px 0 0;
    }
    .camera {
      width: 80px;
    }
    .lp-bottom {
      width: 700px;
    }
    .lp-key {
      width: 820px;
      height: 25px;
    }
    .key-center {
      height: 12px;
      width: 160px;
    }
    .icons-container {
      .top-icons {
        padding: 30px 130px;
        a:nth-child(1) {
          height: 60px;
          width: 180px;
          font-size: 24px;
          line-height: 60px;
          &::before {
            bottom: -20px;
            right: 40px;
            border: 10px solid #0091ff;
            border-left-color: transparent;
            border-bottom-color: transparent;
          }
        }
        a:nth-child(2) {
          height: 56px;
          width: 160px;
          font-size: 22px;
          line-height: 56px;
          transform: translateY(24px);
          &::before {
            border-width: 10px;
            bottom: -15px;
            left: 40px;
          }
        }
      }
      .social-text {
        h1 {
          font-size: 24px;
        }
        p {
          font-size: 18px;
        }
      }
      .bottom-icons {
        padding: 0 180px;
        a:nth-child(1) {
          height: 54px;
          width: 180px;
          line-height: 50px;
          font-size: 16px;
        }
        a:nth-child(2) {
          height: 60px;
          line-height: 55px;
        }
      }
    }
  }
  @media only screen and (max-width: 880px) {
    .lp {
      padding-top: 10px;
      height: 300px;
      width: 500px;
      border-radius: 20px 20px 0 0;
    }
    .camera {
      height: 20px;
    }
    .lp-bottom {
      width: 500px;
      height: 24px;
    }
    .lp-key {
      width: 600px;
      height: 20px;
    }
    .key-center {
      height: 12px;
      width: 120px;
    }
    .icons-container {
      .top-icons {
        padding: 24px 100px;
        a:nth-child(1) {
          width: 220px;
          height: 40px;
          font-size: 18px;
          line-height: 40px;
          &::before {
            bottom: -12px;
            right: 20px;
            border-width: 7px;
          }
        }
        a:nth-child(2) {
          height: 46px;
          width: 240px;
          font-size: 18px;
          line-height: 46px;
          transform: translateY(24px);
          &::before {
            border-width: 7px;
            bottom: -14px;
            left: 20px;
          }
        }
      }
      .social-text {
        h1 {
          font-size: 20px;
        }
        p {
          font-size: 14px;
        }
      }
      .bottom-icons {
        margin-top: 5px;
        padding: 0 80px;
        a:nth-child(1) {
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }

  @media only screen and (max-width: 640px) {
    .lp {
      padding-top: 8px;
      height: 230px;
      width: 360px;
      border-radius: 20px 20px 0 0;
    }
    .camera {
      height: 16px;
    }
    .dot {
      height: 6px;
      width: 6px;
    }
    .lp-bottom {
      width: 360px;
      height: 18px;
    }
    .lp-key {
      display: none;
    }
    .key-center {
      display: none;
    }
    .icons-container {
      .top-icons {
        justify-content: center;
        padding: 0;
        padding-top: 12px;
        a:nth-child(1) {
          width: 120px;
          height: 40px;
          font-size: 12px;
          line-height: 40px;
        }
        a:nth-child(2) {
          height: 40px;
          width: 100px;
          font-size: 12px;
          line-height: 40px;
          transform: translateY(10px);
        }
      }
      .social-text {
        margin-top: 20px;
        h1 {
          font-size: 14px;
        }
        p {
          font-size: 12px;
          width: 60%;
        }
      }
      .bottom-icons {
        margin-top: 5px;
        padding: 0px;
        justify-content: center;
        a:nth-child(1) {
          height: 36px;
          width: 120px;
          font-size: 12px;
          line-height: 30px;
        }
        a:nth-child(2) {
          height: 40px;
          width: 100px;
          line-height: 36px;
          font-size: 12px;
          margin: 12px;
        }
      }
    }
  }
}

.camera {
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  height: 24px;
  width: 100px;
  background-color: #000;
  border-radius: 10px;
}
.dot {
  position: absolute;
  left: 50%;
  top: 4px;
  transform: translateX(-50%);
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  background-color: #272c34;
}
.lp-bottom {
  width: 900px;
  height: 30px;
  background-color: rgb(43, 43, 43);
  border: 1px solid rgb(67, 67, 67);
  border-radius: 0 0 26px 26px;
  box-shadow: 12px 0 10px rgba(111, 111, 111, 0.5) inset,
    -12px 0 10px rgba(111, 111, 111, 0.5) inset;
  transition: 600ms ease-out;
}
.lp-key {
  position: relative;
  margin-top: -12px;
  height: 30px;
  width: 1050px;
  border-bottom: 0.5px solid rgb(30, 30, 30);
  background-color: #bec3cb;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.25),
    0 8px 5px rgba(0, 0, 0, 0.5) inset, 0 -8px 5px rgba(0, 0, 0, 0.5) inset,
    10px 0 5px rgba(0, 0, 0, 0.5) inset;
}
.key-center {
  position: absolute;
  height: 15px;
  width: 240px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25) inset,
    4px 0 5px rgba(0, 0, 0, 0.25) inset, -4px 0 5px rgba(0, 0, 0, 0.25) inset,
    0 -1px 4px rgba(0, 0, 0, 0.5) inset;
  border-radius: 0 0 16px 16px;
}
.icons-container {
  height: 100%;
  width: 100%;
  position: relative;

  .top-icons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 50px 100px;
    perspective: 500px;
    a {
      display: block;
      text-decoration: none;
      color: #fff;
      text-align: center;
      font-weight: bold;
      position: relative;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.24);
    }

    a:nth-child(1) {
      height: 100px;
      width: 260px;
      border-radius: 60px;
      background-color: #0091ff;
      font-size: 36px;
      line-height: 100px;
      opacity: 0;
      animation: icon1 400ms ease-in-out 1.5s 1 forwards,
        heartBeatEffect 800ms linear 2.5s infinite;
      &::before {
        content: "";
        position: absolute;
        bottom: -40px;
        right: 60px;
        border: 20px solid #0091ff;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }
    a:nth-child(2) {
      line-height: 86px;
      font-size: 28px;
      height: 86px;
      width: 240px;
      background-color: #3b5cf2;
      border-radius: 43px;
      transform: translateY(30px);
      opacity: 0;
      animation: icon2 400ms ease-in-out 2s 1 forwards,
        heartBeatEffect 900ms linear 2.7s infinite;

      &::before {
        content: "";
        position: absolute;
        bottom: -30px;
        left: 60px;
        border: 16px solid #3b5cf2;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }
  .social-text {
    text-align: center;
    h1 {
      font-size: 32px;
      font-weight: 900;
      opacity: 0;
      margin: 0 auto;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      animation: increment 2s steps(16, end) infinite forwards;
      animation-delay: 500ms;
    }
    p {
      margin: 8px auto;
      width: 40%;
      font-size: 22px;
      opacity: 0;
      animation: grow 400ms ease-in-out 1 forwards;
    }
  }
  .bottom-icons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 220px;
    margin-top: 20px;
    perspective: 1000px;
    a {
      text-decoration: none;
      color: #fff;
      display: block;
      text-align: center;
      position: relative;
      background-color: #fff;
    }
    a:nth-child(1) {
      line-height: 80px;
      height: 80px;
      width: 216px;
      color: #0160fc;
      font-weight: bold;
      font-size: 24px;
      border-radius: 40px;
      border: 3px solid #000;
      position: relative;
      animation: icon3 400ms ease-in-out 2.5s 1 forwards,
        heartBeatEffect 900ms linear 3s infinite;
      opacity: 0;
    }
    a:nth-child(2) {
      height: 90px;
      width: 200px;
      border-radius: 45px;
      border: 3px solid #000;
      color: #1d5686;
      font-size: 20px;
      margin-top: 15px;
      margin-left: 15px;
      font-weight: bold;
      line-height: 90px;
      animation: icon4 400ms ease-in-out 3s 1 forwards,
        heartBeatEffect 900ms linear 3.5s infinite;
      opacity: 0;
    }
  }
}

/* List of Animations Applied */
@keyframes open {
  0% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes grow {
  0% {
    opacity: 0;
  }
  50% {
    line-height: 0.5;
  }
  100% {
    height: 100%;
    line-height: 1;
    opacity: 1;
  }
}
@keyframes increment {
  0% {
    width: 0%;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}
@keyframes icon1 {
  0% {
    transform: translate(0px, 100px) scale(0) rotateY(-30deg);
  }
  50% {
    transform: translate(0px, 60px) scale(0.5) rotateY(30deg);
  }
  100% {
    transform: translateX(0, 0) scale(1) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes icon2 {
  0% {
    transform: translate(0px, 100px) scale(0) rotateY(30deg);
  }
  50% {
    transform: translate(0px, 60px) scale(0.5) rotateY(-30deg);
  }
  100% {
    transform: translateX(0, 0) scale(1) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes icon3 {
  0% {
    transform: translate(0px, -100px) scale(0) rotateY(30deg);
  }
  50% {
    transform: translate(0px, -60px) scale(0.5) rotateY(-50deg);
  }
  100% {
    transform: translateX(0, 0) scale(1) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes icon4 {
  0% {
    transform: translate(0px, -100px) scale(0) rotateY(30deg);
  }
  50% {
    transform: translate(0px, -60px) scale(0.5) rotateY(-60deg);
  }
  100% {
    transform: translateX(0, 0) scale(1) rotateY(0deg);
    opacity: 1;
  }
}
@keyframes heartBeatEffect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

/* Linear Gradients */
